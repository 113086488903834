import styled from "styled-components"

const Button = styled.button`
  background: ${({ theme }) => theme.background};
  border: 2px solid ${({ theme }) => theme.toggleBorder};
  color: ${({ theme }) => theme.text};
  border-radius: 30px;
  cursor: pointer;
  font-size:0.8rem;
  padding: 0.6rem;
  }
`;

const Toggle = ({theme,  toggleTheme }: IToggleProps) => {
    return (
        <Button onClick={toggleTheme} >
          Switch Theme
        </Button>
    );
};

interface IToggleProps {
  theme: string,
  toggleTheme: (() => void)
}

export default Toggle;