// React
import { useState } from 'react';

// Data
import { ICuisine, cuisines } from './data/cuisines';

// Components
import { useDarkMode } from './components/useDarkMode';
import Toggle from './components/Toggler';

// Style
import './App.css';
import { GlobalStyles } from './theme/globalStyles';
import styled, { ThemeProvider } from "styled-components"
import { darkTheme, lightTheme } from './theme/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  @media (max-width: 768px) {
    width: 50%;
  }
  padding: 1em;
  border: 1px  ${({ theme }) => theme.text} solid;
  border-radius: 8px;
  margin: 2em;
  min-height: 280px;
`

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  align-text: center;
  display: inline-block;

`

const ListContainer = styled.div`
  text-align: center;
`

const StyledButton = styled.button`
  padding: 1em;
  background-color: ${({ theme }) => theme.background};
  border: 1px black solid;
  border-radius: 8px;
`

const ListTitle = styled.h3`
  margin-bottom: 0;
`

const TextButton = styled.h3`
  margin: 2em;
  &:hover {
    color: ${({ theme }) => theme.background};
    cursor: pointer;
  }
`

const App = () => {

  const [cuisine, setCuisine] = useState<ICuisine>()
  const {theme, themeToggler} = useDarkMode();

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  const getCuisine = () => {
    const randomNumber = Math.floor(Math.random() * cuisines.length)
    setCuisine(cuisines[randomNumber])
  }

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <Container>
          <Toggle theme={theme} toggleTheme={themeToggler} />
          {
            cuisine ? (
              <>
                <h1>Quizine</h1>
                <Card>
                  <h2>{cuisine.name}</h2>
                  <img src={cuisine.flag} alt={`The flag of ${cuisine.name}`} />
                  <div>
                    <ListTitle>Restaurants</ListTitle>
                    <ListContainer>
                      <List>
                        {cuisine.restaurants.map((restaurant, index) => {
                          return <li key={index}>{restaurant}</li>;
                        })}
                      </List>
                    </ListContainer>
                  </div>
                </Card>
                <StyledButton onClick={() => {getCuisine()}}>Next Cuisine</StyledButton>
              </>
            )
            : <TextButton onClick={() => {getCuisine()}}>Click to pick a cuisine</TextButton>
          }
        </Container>
      </>
    </ThemeProvider>
  );
}

export default App;
