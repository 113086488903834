export interface ICuisine {
    name: string,
    flag: string,
    restaurants: string[],
}

export const cuisines: ICuisine[] = [
  {
    name: "Australian",
    flag: "https://flagsapi.com/AU/shiny/64.png",
    restaurants: ["Australasia"],
  },
  {
    name: "New Zealand",
    flag: "https://flagsapi.com/NZ/shiny/64.png",
    restaurants: ["Tahi"],
  },
  {
    name: "Armenian",
    flag: "https://flagsapi.com/AM/shiny/64.png",
    restaurants: ["Armenian Taverna"],
  },
  {
    name: "Belgian",
    flag: "https://flagsapi.com/BE/shiny/64.png",
    restaurants: ["Belgian Bar"],
  },
  {
    name: "European",
    flag: "https://flagsapi.com/PL/shiny/64.png",
    restaurants: ["The Sparrows"],
  },
  {
    name: "French",
    flag: "https://flagsapi.com/FR/shiny/64.png",
    restaurants: ["Cote", "63 Degrees", "Chez Mal"],
  },
  {
    name: "Georgian",
    flag: "https://flagsapi.com/GE/shiny/64.png",
    restaurants: ["Mimino"],
  },
  {
    name: "Greek",
    flag: "https://flagsapi.com/GR/shiny/64.png",
    restaurants: ["Dimitri's", "Rozafa"],
  },
  {
    name: "Italy",
    flag: "https://flagsapi.com/IT/shiny/64.png",
    restaurants: ["Salvi's"],
  },
  {
    name: "Polish",
    flag: "https://flagsapi.com/PL/shiny/64.png",
    restaurants: ["Platzki"],
  },
  {
    name: "Portugal",
    flag: "https://flagsapi.com/PT/shiny/64.png",
    restaurants: ["Meu", "Canto"],
  },
  {
    name: "Spain",
    flag: "https://flagsapi.com/ES/shiny/64.png",
    restaurants: ["El Rincon", "La Bandera", "El Gato Negro"],
  },
  {
    name: "Ethiopia",
    flag: "https://flagsapi.com/ET/shiny/64.png",
    restaurants: ["Habesha"],
  },
  {
    name: "Libya",
    flag: "https://flagsapi.com/LY/shiny/64.png",
    restaurants: ["Zoroona"],
  },
  {
    name: "South African",
    flag: "https://flagsapi.com/ZA/shiny/64.png",
    restaurants: ["Chakalaka"],
  },
  {
    name: "Nigerian",
    flag: "https://flagsapi.com/NG/shiny/64.png",
    restaurants: ["Yetti's Kitchen"],
  },
  {
    name: "Cambodian",
    flag: "https://flagsapi.com/KH/shiny/64.png",
    restaurants: ["Kambuja"],
  },
  {
    name: "Chinese",
    flag: "https://flagsapi.com/CN/shiny/64.png",
    restaurants: ["Little Yang Sing"],
  },
  {
    name: "East Asian",
    flag: "https://flagsapi.com/LA/shiny/64.png",
    restaurants: ["Tampopo", "Wagamama"],
  },
  {
    name: "Filipino",
    flag: "https://flagsapi.com/PH/shiny/64.png",
    restaurants: ["Yes Lah"],
  },
  {
    name: "Japanese",
    flag: "https://flagsapi.com/JP/shiny/64.png",
    restaurants: ["Yuzu", "Samsi"],
  },
  {
    name: "Korean",
    flag: "https://flagsapi.com/KR/shiny/64.png",
    restaurants: ["Baekdu", "Koreana"],
  },
  {
    name: "Malaysian",
    flag: "https://flagsapi.com/MY/shiny/64.png",
    restaurants: ["Chef Diao", "Nur Malaysia"],
  },
  {
    name: "Thai",
    flag: "https://flagsapi.com/TH/shiny/64.png",
    restaurants: ["Try Thai"],
  },
  {
    name: "Vietnamese",
    flag: "https://flagsapi.com/VN/shiny/64.png",
    restaurants: ["Pho Cue", "Vnam", "Pho No 1"],
  },
  {
    name: "Levantine",
    flag: "https://flagsapi.com/LB/shiny/64.png",
    restaurants: ["Comptoir Libanais", "Remal", "Zena"],
  },
  {
    name: "Middle Eastern",
    flag: "https://flagsapi.com/IR/shiny/64.png",
    restaurants: ["Habas"],
  },
  {
    name: "Turkish",
    flag: "https://flagsapi.com/TR/shiny/64.png",
    restaurants: ["Topkapi Palace", "Cafe Istanbul"],
  },
  {
    name: "Nepalese",
    flag: "https://flagsapi.com/NP/shiny/64.png",
    restaurants: ["The Great Kathmandu", "Namaste"],
  },
  {
    name: "Pakistani",
    flag: "https://flagsapi.com/PK/shiny/64.png",
    restaurants: ["Zouk", "Al Faisal"],
  },
  {
    name: "Sri Lankan",
    flag: "https://flagsapi.com/LK/shiny/64.png",
    restaurants: ["Sigiriya", "Little Lanka"],
  },
  {
    name: "Indian",
    flag: "https://flagsapi.com/IN/shiny/64.png",
    restaurants: ["Arnero", "Dishoom"],
  },
  {
    name: "Brazilian",
    flag: "https://flagsapi.com/BR/shiny/64.png",
    restaurants: ["Cabral's", "Fazenda", "Bem Brasil"],
  },
  {
    name: "Caribbean",
    flag: "https://flagsapi.com/JM/shiny/64.png",
    restaurants: ["Pull Up", "Turtle Bay"],
  },
  {
    name: "Cuba",
    flag: "https://flagsapi.com/CU/shiny/64.png",
    restaurants: ["Cubanitos", "Rev de Cuba"],
  },
  {
    name: "Mexican",
    flag: "https://flagsapi.com/MX/shiny/64.png",
    restaurants: ["Casa Mexica", "El Capo", "La Casita"],
  },
  {
    name: "South American",
    flag: "https://flagsapi.com/PE/shiny/64.png",
    restaurants: ["Peru Perdu", "Gaucho", "Carnival"],
  },
  {
    name: "USA",
    flag: "https://flagsapi.com/US/shiny/64.png",
    restaurants: ["Red's", "Almost Famous"],
  },
];